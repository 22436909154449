import css from "styled-jsx/css";
import { PHONE, DESKTOP } from "utils/constants/ui";

export const magicCheckoutStyles = css`
  @media (max-width: ${PHONE}px) {
    .magic_checkout_link_bg {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
  @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
    .magic_checkout_link_bg {
      padding-bottom: 0px;
      padding-top: 50px;
    }
  }
  @media (min-width: ${DESKTOP}px) {
    .magic_checkout_link_bg {
      padding-bottom: 0px;
      padding-top: 160px;
    }
  }

  @media (max-width: ${PHONE}px) {
    .personalize__container {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
  @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
    .personalize__container {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
  @media (min-width: ${DESKTOP}px) {
    .personalize__container {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
`;
