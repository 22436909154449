import React, { useMemo, Fragment } from "react";
import classnames from "classnames";
import { getIconForUID } from "components/FormerEditor/utils";
import { DURATION, COLORS } from "utils/styles";
import LoadingSpinner from "components/LoadingSpinner";
import Tooltip from "components/Tooltip";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_CLASSES = {
  tooltip: {},
  button:
    "ma0 items-center flex pa2 dib items-center justify-center bn br1 panel--action relative bg-white bg-content--secondary bg-content--hover"
};

const HoverIconButton = ({
  onClick,
  type,
  icon,
  hideIcon,
  size = 16,
  loading,
  color,
  disabled,
  tooltip,
  tooltipPosition,
  customClasses,
  children
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const Icon = getIconForUID(type || icon);
  const NO_OP = () => {};
  const tooltipId = useMemo(() => uuidv4(), []);

  const buttonClasses = classnames(classes.button, {
    "pointer": !disabled,
    "cursor-not-allowed": disabled,
    "bg-white o-30": disabled
  });

  const buttonProps = {
    style: { outline: "none" },
    disabled,
    type: "button",
    onClick: (!disabled && onClick) || NO_OP,
    className: buttonClasses
  };
  const iconColor = disabled ? COLORS.deselected : color || COLORS.icon;

  return (
    <Tooltip
      id={tooltipId}
      customClasses={classes.tooltip}
      tooltip={!disabled ? tooltip : ""}
      position={tooltipPosition}
    >
      <button {...buttonProps}>
        {loading ? (
          <LoadingSpinner
            customClasses={{
              ring: "blue",
              size: "small"
            }}
          />
        ) : (
          <Fragment>
            {children}
            {!hideIcon && <Icon size={size} color={iconColor} />}
          </Fragment>
        )}
        <style jsx>
          {`
            .panel--action {
              -webkit-transition: background-color ${DURATION * 2}s,
                -webkit-transform ${DURATION * 2}s;
              transition: background-color ${DURATION * 2}s,
                transform ${DURATION * 2}s;
            }
          `}
        </style>
      </button>
    </Tooltip>
  );
};

export default HoverIconButton;
