import React, { Fragment } from "react";
import { TOOLTIP_POSITION } from "utils/constants/ui";
import dynamic from "next/dynamic";
const ReactTooltipLoader = () => <span></span>;
const ReactTooltip = dynamic(() => import("react-tooltip"), {
  ssr: false,
  loading: ReactTooltipLoader
});
const DEFAULT_CLASSES = {
  children: "tl",
  tooltip: "bg-black-90 white dn-imp-s dib-ns"
};

const Tooltip = ({ tooltip, children, position, id, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  if (!id && tooltip) {
    throw new Error("Tooltip needs a unique id");
  }

  const pos = position || TOOLTIP_POSITION.TOP;

  if (tooltip && tooltip.trim()) {
    return (
      <Fragment>
        <div className={classes.children} data-tip data-for={id}>
          {children}
        </div>
        {/* Prevent FOUC on server side render */}
        <ReactTooltip
          id={id}
          className={classes.tooltip}
          effect="solid"
          place={pos}
        >
          {tooltip}
        </ReactTooltip>
      </Fragment>
    );
  } else {
    return children;
  }
};

export default Tooltip;
