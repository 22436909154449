import React from "react";
import classnames from "classnames";
// Payment links: 289711
// Tip pages: 291501
// No code checkout platform: NNNNNN
const POST_ID = "356247";
const POST_PATH = "no-code-checkout-forms";
const UTM_SOURCE = `badge-${POST_PATH}`;
const PH_LINK = `https://www.producthunt.com/posts/${POST_PATH}?utm_source=badge-featured&utm_medium=badge&utm_souce=${UTM_SOURCE}`;
const PH_IMAGE = `https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=${POST_ID}&theme=light`;
// Toggle to show for launches
const HIDE = true;

const ProductHuntBanner = () => (
  <div
    className={classnames("top-0 left-0 right-0 ph2 bb hairline-1", {
      dn: HIDE
    })}
  >
    <div className="mw8 center flex flex-row items-center justify-between ph2 ph3-l pt2 pb1">
      <div className="f6 f5-ns fw6">Live on Product Hunt!</div>
      <a href={PH_LINK} target="_blank">
        <img
          src={PH_IMAGE}
          alt="No code checkout forms - Capture payment and data in one embeddable checkout form | Product Hunt"
          style={{ width: 180, height: 40 }}
          width="180"
          height="40"
        ></img>
      </a>
    </div>
  </div>
);

export default ProductHuntBanner;
