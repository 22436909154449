import React from "react";
import { withRouter } from "next/router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LeadCTASection from "components/LeadCTASection";
import Footer from "components/Footer";
import PATHS from "utils/paths";
import { getAppUserCapabilities } from "utils/mapStateToProps";
import { capabilityFlags } from "utils/capability";
import { landingStyles } from "./landingStyles";
import ProductHuntBanner from "components/ProductHuntBanner";
import {
  fetchMerchantAccountProductsPrices,
  fetchMerchantAccountCouponsPromotionCodes
} from "actions/merchantAccount";
import { fetchMerchantAccountTaxRates } from "actions/taxRate";
import { magicCheckoutStyles } from "components/Landing/MagicCheckout/magicCheckoutStyles";
import { createUserLead } from "actions/user";
import {
  NO_CODE_EDITOR_VIDEO_SECTION,
  PRODUCT_TAG_HEADER
} from "constants/marketing";
import HighlightSection from "components/Marketing/Components/HighlightSection";
import VideoSection from "components/Marketing/Components/VideoSection";
import {
  FORM_HIGHLIGHT_SECTION,
  LOGIC_AND_PREFILL_HIGHLIGHT_SECTION,
  AUTOMATION_HIGHLIGHT_SECTION
} from "constants/marketing/form";
import { BUTTON_TAGS, WIDE_RIGHT_HIGHLIGHT_CLASSES } from "utils/constants/ui";
import CodePreview from "components/CodePreview";
import { SNIPPET_SALE_EXAMPLE } from "constants/snippets";
import { LANGUAGE_TYPE } from "constants/docs";
import HeroBanner from "components/Marketing/Components/HeroBanner";
import IconLink from "components/IconLink";
import Button from "components/Form/fields/Button";
import ContentCTA from "components/Marketing/Components/ContentCTA";

const Landing = () => {
  const heroProps = {
    header: [
      {
        copy: "The"
      },
      {
        classes: "color-primary underline",
        copy: "no code"
      },
      {
        classes: "",
        copy: "checkout platform"
      }
    ],
    subheader: PRODUCT_TAG_HEADER,
    hideAction: true
  };

  return (
    <div className="w-100 relative ph3-l">
      <ProductHuntBanner />
      <HeroBanner
        {...heroProps}
        altActions={
          <div className="flex center w-100 justify-center pt3">
            <IconLink
              customClasses={{
                link: "fw6 f5 pr1 link color-primary"
              }}
              href={PATHS.BUILDER_APPLICATIONS}
              copy="Try it"
              icon="rightArrow"
            />
            <div className="pl2">
              <Button
                copy="Build a form"
                classes={{
                  button:
                    "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex flex-shrink-0"
                }}
                href={PATHS.BUILDER_APPLICATIONS}
                tag={BUTTON_TAGS.LINK}
              ></Button>
            </div>
          </div>
        }
      />
      <div className="mt3 mt5-l">
        <VideoSection {...NO_CODE_EDITOR_VIDEO_SECTION} />
      </div>
      <div className="pt4">
        <HighlightSection
          {...FORM_HIGHLIGHT_SECTION}
          customClasses={WIDE_RIGHT_HIGHLIGHT_CLASSES}
        />
      </div>
      <div className="pt5 pt0-ns">
        <ContentCTA
          {...{
            header: {
              title: "Build your own checkout",
              body: [
                "Explore our form templates and try building your own checkout."
              ]
            },
            action: (
              <div className="flex center w-100 justify-center pt3">
                <IconLink
                  customClasses={{
                    link: "fw6 f5 pr1 link color-primary"
                  }}
                  href={PATHS.BUILDER_APPLICATIONS}
                  copy="Try it"
                  icon="rightArrow"
                />
                <div className="pl2">
                  <Button
                    copy="Build a form"
                    classes={{
                      button:
                        "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex flex-shrink-0"
                    }}
                    href={PATHS.BUILDER_APPLICATIONS}
                    tag={BUTTON_TAGS.LINK}
                  ></Button>
                </div>
              </div>
            )
          }}
        />
      </div>
      <div className="pt4">
        <HighlightSection
          {...LOGIC_AND_PREFILL_HIGHLIGHT_SECTION}
          customClasses={WIDE_RIGHT_HIGHLIGHT_CLASSES}
        />
      </div>
      <HighlightSection
        {...AUTOMATION_HIGHLIGHT_SECTION}
        right={
          <CodePreview
            title="Checkout data example"
            hideCopyAction
            staticMaxHeight={520}
            customClasses={{
              snippet: {
                pre: "code__pre--preview--small ma0 overflow-auto f6"
              }
            }}
            snippets={{ [LANGUAGE_TYPE.JSON]: SNIPPET_SALE_EXAMPLE }}
          />
        }
      />
      <div className="pv4 mw8 center">
        <LeadCTASection
          header="Ready to create custom checkouts for your business?"
          subheader="Sign up to get started."
          action={{ href: PATHS.SIGNUP, copy: "Sign up" }}
        />
      </div>
      {/* <!-- Footer  --> */}
      <div className="mw8 center ">
        <div className="pt5 hairline-1 bt">
          <Footer />
        </div>
      </div>
      <style jsx>{landingStyles}</style>
      <style jsx>{magicCheckoutStyles}</style>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    flags: capabilityFlags(getAppUserCapabilities(state))
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchMerchantAccountProductsPrices,
      fetchMerchantAccountCouponsPromotionCodes,
      fetchMerchantAccountTaxRates,
      createUserLead
    },
    dispatch
  )
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Landing)
);
