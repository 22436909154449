import React from "react";
import PATHS from "utils/paths";
import Link from "next/link";
import BrandIcon from "components/Icons/brand";
import FooterColumns from "components/FooterColumns";
import { crispComposerWithMessage } from "utils/crisp";

const FOOTER_COLUMNS = [
  {
    header: "Payment pages",
    nodes: [
      {
        href: PATHS.BUILDER_APPLICATIONS,
        title: "Templates"
      },
      {
        href: PATHS.BUILDER_APPLICATIONS,
        title: "Builder"
      }
    ]
  },
  {
    header: "Payment links",
    nodes: [
      {
        href: PATHS.FEATURES_LINKS,
        title: "Overview"
      },
      {
        href: PATHS.FEATURES_LINKS_API,
        title: "API",
        callout: "New"
      },
      {
        href: PATHS.FEATURES_LINKS_API,
        title: "Advanced"
      },
      {
        href: PATHS.FEATURES_LINKS_BUILDER,
        title: "Builder"
      },
      {
        href: PATHS.FEATURES_CONTACTLESS,
        title: "Contactless"
      },
      {
        href: PATHS.FEATURES_DIGITAL_CONTENT,
        title: "Digital content"
      },
      {
        href: PATHS.FEATURES_CHECKOUT,
        title: "Checkout"
      },
      {
        href: PATHS.FEATURES_CHANNELS,
        title: "Channels"
      }
    ]
  },
  {
    header: "Integrations",
    nodes: [
      {
        href: PATHS.FEATURES_INTEGRATIONS_STRIPE,
        title: "Stripe"
      },
      {
        href: PATHS.FEATURES_INTEGRATIONS_ZAPIER,
        title: "Zapier"
      },
      {
        href: PATHS.FEATURES_INTEGRATIONS_WEBFLOW,
        title: "Webflow"
      }
    ]
  },
  {
    header: "Resources",
    nodes: [
      {
        href: PATHS.LEARN,
        title: "Learn"
      },
      {
        href: PATHS.TUTORIALS,
        title: "Tutorials"
      },
      {
        href: PATHS.DOCS,
        title: "Documentation"
      },
      {
        href: PATHS.TOOLS_QR_CODES,
        title: "Design a QR code"
      },
      {
        href: PATHS.TOOLS_QR_CODES_STRIPE,
        title: "Stripe QR codes"
      }
    ]
  },
  {
    header: "Pricing",
    nodes: [
      {
        href: PATHS.PRICING,
        title: "Select a Plan"
      }
    ]
  },
  {
    header: "Company",
    nodes: [
      {
        onClick: () => crispComposerWithMessage(),
        title: "Contact us"
      }
    ]
  }
];

const Footer = () => {
  const columns = <FooterColumns columns={FOOTER_COLUMNS} />;

  return (
    <footer className="pa3 ph0-l pv4-m pv4-l flex flex-column items-start">
      <ul className="list ma0 pa0 flex flex-column flex-row-l w-100 pb3 pb4-l">
        {columns}
      </ul>
      <ul className="list flex flex-column flex-row-l justify-between items-center pa0 ma0 w-100">
        <li className="di flex w-100 items-center">
          <Link href={PATHS.HOME}>
            <a className="pt2">
              <BrandIcon />
            </a>
          </Link>
          <div className="di f7 ml2">
            {`${new Date().getFullYear()} DevPlan, Inc. - All rights reserved`}
          </div>
        </li>
        <li className="di flex w-100 flex-row justify-start justify-end-l items-center pt3 pt0-l">
          <Link href={PATHS.PRIVACY}>
            <a className="ttu f7 link color-primary fw5 tc link__decoration--hover">
              Privacy policy
            </a>
          </Link>
          <Link href={PATHS.TERMS}>
            <a className="ttu f7 link color-primary fw5 tc link__decoration--hover ml3 ml4-l mr4-l">
              Terms of Service
            </a>
          </Link>
        </li>
      </ul>
      <div className="pb4 dn-l" />
    </footer>
  );
};

export default Footer;
