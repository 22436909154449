export const SNIPPET_LIBRARY_SINGLE_PRICE = `import { PriceBlocs } from "@priceblocs/react-priceblocs-js";

<PriceBlocs api_key="PB_pk_live_123" prices={["price_123"]}>
  {({ values: { products }, checkout }) => {
    const { name, prices } = products[0];
    const { id, formatted: { unit_amount } } = prices[0];
    return (
      <div>
        <h2>{\`Upgrade to \${name} for \${unit_amount}\`}</h2>
        <button onClick={() => checkout(id)}>
          Upgrade
        </button>
      </div>
    );
  }}
</PriceBlocs>`;

export const SNIPPET_SALE_EXAMPLE = `{
  id: "f735bf7e-a673-4df9-bf28-6928afae8023",
  session_id:
    "cs_test_a1FfrPO4Inl4C9kVSLIBsu5pMf58dqsgMzZ3AOChzdraa3HvePbHiTHERe",
  status: "started",
  line_items: [
    {
      id: "li_123",
      amount_subtotal: 10000,
      amount_total: 10000,
      unit_amount: 10000,
      description: "Product A",
      price: "price_1ILX6XAjcnPhf83W9c3PXMRS",
      product: "prod_IxS0n5F4IOmsbE",
      currency: "usd",
      quantity: 1,
      interval: "month"
    }
  ],
  form_data: [
    {
      label: "First name",
      uid: "input",
      uuid: "ebeab0ef-f25f-47fa-9c05-117bcc57beac",
      value: "Sally",
      meta: null,
      error: null
    },
    {
      label: "US EIN",
      uid: "tin",
      uuid: "d8520ea9-6e7e-4c05-8830-f6476e609c75",
      value: "12-3456789",
      meta: {
        value: "12-3456789",
        code: "US",
        type: "us_ein"
      },
      error: null
    },
    {
      label: "Select one",
      uid: "select",
      uuid: "16ba5574-b60d-4ce3-96a7-79805c0ead63",
      value: "Option 2",
      meta: {
        options: [
          {
            uuid: "6a6b6649-c521-4585-83af-4d64a830d866",
            value: "Option 1"
          },
          {
            uuid: "16ba5574-b60d-4ce3-96a7-79805c0ead63",
            value: "Option 2"
          }
        ]
      },
      error: null
    },
    {
      label: "Datepicker",
      uid: "datepicker",
      uuid: "9947ae73-dcd5-40b0-a8b2-5b1d3b32cb5b",
      value: "2021-12-16T19:45:21.000Z",
      meta: {
        value: "2021-12-16T19:45:21.000Z",
        utc: "2021-12-16T19:45:21.000Z",
        presentation: {
          format: null
        }
      },
      error: null
    },
    {
      label: "Checkbox",
      uid: "checkbox",
      uuid: "f04feca3-0991-4eb7-ad16-8553a2572868",
      value: true,
      meta: null,
      error: null
    },
    {
      label: "Address",
      uid: "address",
      uuid: "d20a3606-bfe3-40df-b52d-bb0d16e33912",
      value: {
        receiver: {
          name: "Sally Smith"
        },
        address: {
          city: "San Francisco",
          country: "US",
          line1: "600 Montgomery St",
          line2: null,
          postal_code: "94111",
          state: "CA"
        }
      },
      meta: {
        place_id: "ChIJQ-U7wYqAhYAReKjwcBt6SGU"
      },
      error: null
    },
    {
      error: null,
      label: "Multiselect one",
      meta: {
        options: [
          {
            uuid: "multiselect-s-option-uuid-1",
            value: "Option 1"
          },
          {
            uuid: "multiselect-s-option-uuid-2",
            value: "Option 2"
          },
          {
            uuid: "multiselect-s-option-uuid-3",
            value: "Option 3"
          },
          {
            uuid: "multiselect-s-option-uuid-4",
            value: "Option 4"
          }
        ]
      },
      uid: "multiselect",
      uuid: "40eca945-6b8d-4d38-aad3-c6956d5d115a",
      value: ["Option 2", "Option 3"]
    },
    {
      label: "Toggle",
      uid: "toggle",
      uuid: "919c1540-0db0-41b1-b3c4-71aa41d70886",
      value: true,
      meta: null,
      error: null
    }
  ],
  amount_total: 10000,
  amount_tax: 0,
  amount_discount: 0,
  amount_subtotal: 10000,
  mode: "subscription",
  customer: "cus_123",
  email: "customer@company.com",
  currency: "usd",
  livemode: false,
  object: "checkout",
  subscription_id: null,
  payment_intent_id: null,
  setup_intent_id: null,
  client_reference_id: null,
  payment_link_id: "04e3cac1-f687-44ae-b45a-370c923aaf93",
  payment_link_short_id: "123",
  page_id: "04e3cac1-f687-44ae-b45a-370c923aaf93",
  route: "/demo",
  via: "partner",
  ip: "127.0.0.1",
  os: "Mac OS X 10.15.7",
  origin: "https://priceblocs.com",
  browser: "Chrome",
  user_agent:
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.131 Safari/537.36",
  device: "Other 0.0.0",
  device_version: "0.0.0",
  fulfillment: null,
  payment_type: "recurring",
  automatic_tax: false,
  tax_id_collection: false,
  payment_status: "unpaid",
  trial_period_days: 0,
  payment_intent_capture_method: null,
  shipping_options: [
    {
      shipping_rate: "shr_123"
    },
    {
      shipping_rate: "shr_456"
    }
  ],
  shipping_rate: "shr_456",
  tax_rates: null,
  coupons: "123,999",
  promotion_codes: "456",
  recovery_event_id: null,
  customer_promotion_codes: "BLACKFRIDAY",
  discounts: [
    {
      amount: 0,
      discount: {
        coupon: {
          id: "123"
        },
        promotion_code: "456"
      }
    }
  ],
  metadata: {
    partner: "partner",
    subdomain: "priceblocs"
  },
  tracking: {
    gclid: "gclid",
    mc_cid: "mc_cid",
    utm_source: "utm_source",
    via: "partner"
  },
  created: 1628956460,
  updated: 1628956460
}`;
