import React, { useState, useEffect } from "react";
import { linkReplace, oembed } from "@loomhq/loom-embed";

const LOOM_SHARE_URL = "https://www.loom.com/share";
const SELECTOR_ROOT = "loom-video";

const LoomEmbed = ({ id, children }) => {
  const url = `${LOOM_SHARE_URL}/${id}`;
  const [metadata, setMetadata] = useState();
  const selector = `${SELECTOR_ROOT}-${id}`;

  const loadVideo = async () => {
    linkReplace(`.${selector}`);
    if (process.env.NODE_ENV === "development") {
      const metadata = await oembed(url);
      console.log(`${selector} metadata:`, metadata);
    }

    setMetadata(metadata);
  };

  useEffect(() => {
    loadVideo();
  }, [id]);
  const content = <a className={selector} href={url}></a>;

  return children ? (
    <div className="relative">
      {content}
      {children}
    </div>
  ) : (
    content
  );
};

export default LoomEmbed;
