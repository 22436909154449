import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from "react";
import ReactModal from "react-modal";
import IconButton from "components/IconButton";
import { COLORS } from "utils/styles";
import Scroll from "utils/scroll";
import { isSm } from "utils/view";
import { crispHide, crispShow } from "utils/crisp";
import componentStyles from "./componentStyles";
import { useOnClickOutside } from "utils/hooks/ui";

ReactModal.setAppElement("#root");

const DEFAULT_CLASSES = {
  modal: "modal__content br2-ns",
  overlay: "modal__overlay",
  container:
    "bg-white bn ba-ns br2-ns b--black-05 shadow-0 flex flex-column center w-100 h-100 h-auto-ns w-50-m w-40-l mw6 outline-1",
  action: "flex justify-end pt2 pr2"
};

const Modal = (props) => {
  const containerRef = useRef();
  const scroll = useMemo(() => new Scroll(), []);

  const onClose = props.onClose;
  const [showModal, setShowModal] = useState(props.showModal || false);

  useEffect(() => {
    if (props.showModal !== showModal) {
      setShowModal(props.showModal);
    }
  }, [props.showModal]);

  const showRoutine = () => {
    crispHide();
    isSm() && scroll.fix();
  };

  const hideRoutine = () => {
    isSm() && scroll.unfix();
    crispShow();
    onClose && onClose();
  };

  useEffect(() => {
    showModal ? showRoutine() : hideRoutine();
  }, [showModal]);

  const handleCloseModal = () => {
    hideRoutine();
    setShowModal(false);
  };

  const hideOnClickOutside = useCallback(() => {
    handleCloseModal();
  }, []);
  useOnClickOutside(containerRef, hideOnClickOutside);

  const { showCloseIcon, customClasses } = props;
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const iconProps = {
    iconType: "close",
    iconProps: {
      size: 26
    },
    colors: {
      hover: COLORS.text,
      active: COLORS.text,
      inactive: COLORS.text
    },
    onClick: handleCloseModal
  };

  return (
    <ReactModal
      className={classes.modal}
      overlayClassName={classes.overlay}
      closeTimeoutMS={0}
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      contentLabel="PriceBlocs modal"
      shouldCloseOnOverlayClick
    >
      <div ref={containerRef} className={classes.container}>
        {showCloseIcon && (
          <div className={classes.action}>
            <IconButton {...iconProps} />
          </div>
        )}
        {props.children}
      </div>
      <style jsx>{componentStyles}</style>
    </ReactModal>
  );
};

export default Modal;
