import React, { Fragment } from "react";
import LoomEmbed from "./LoomEmbed";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { styles } from "components/VideoPreview/styles";
import {
  COMMAND_SERVICE,
  YOU_TUBE_EMBED_KEYS,
  DEFAULT_SERVICE_CONFIG
} from "components/CommandPalette/constants";

const Loom = ({ service_uid, children }) => {
  return <LoomEmbed id={service_uid}>{children}</LoomEmbed>;
};

const YouTube = ({ service_uid, config, children }) => {
  const props = {
    id: service_uid
  };

  YOU_TUBE_EMBED_KEYS.reduce((memo, key) => {
    const value = config[key];
    if (typeof value !== "undefined") {
      props[key] = value;
    }
    return memo;
  }, {});

  const content = (
    <Fragment>
      <LiteYouTubeEmbed {...props} />
      <style global jsx>
        {styles}
      </style>
    </Fragment>
  );

  return children ? (
    <div className="relative">
      {content}
      {children}
    </div>
  ) : (
    content
  );
};

const SERVICE_COMPONENT = {
  [COMMAND_SERVICE.LOOM]: Loom,
  [COMMAND_SERVICE.YOU_TUBE]: YouTube
};

/**
 * @param {Object} props
 * @param {String} props.id - id
 * @param {String} props.title - video title
 * @param {String} props.service - originating service (Loom / YouTube)
 * @param {String} props.service_uid - service id
 * @param {String} props.type - video etc.
 * @param {Object} props.config - player specific config
 * @param {Object} props.children
 * @returns
 */
const VideoEmbed = ({ service, config, children, ...remainder }) => {
  const ServiceComp = SERVICE_COMPONENT[service];
  const serviceConfig = {
    ...DEFAULT_SERVICE_CONFIG[service],
    ...config
  };

  return (
    <ServiceComp service={service} config={serviceConfig} {...remainder}>
      {children}
    </ServiceComp>
  );
};

export default VideoEmbed;
