import css from "styled-jsx/css";
import {
  CONTACTLESS_SERVICES,
  SOCIAL_SERVICE,
  VIDEO_SERVICES,
  EMAIL_SERVICES,
  SMS_SERVICES,
  USE_CASES
} from "utils/constants/useCases";
import { PHONE, DESKTOP } from "utils/constants/ui";
import IMAGES from "utils/constants/image";

export const landingStyles = css`
  :global(.useCase__icon--${USE_CASES.EMAIL}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.EMAIL}.svg");
  }
  :global(.useCase__icon--${USE_CASES.VIDEO}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.VIDEO}.svg");
  }
  :global(.useCase__icon--${USE_CASES.SMS}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.SMS}.svg");
  }
  :global(.useCase__icon--${USE_CASES.CONTACTLESS}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.CONTACTLESS}.svg");
  }
  :global(.useCase__icon--${USE_CASES.SUPPORT}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.SUPPORT}.svg");
  }
  :global(.useCase__icon--${USE_CASES.SOCIAL}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.SOCIAL}.svg");
  }
  :global(.useCase__icon--${USE_CASES.COMMERCE}::after) {
    background-image: url("/assets/useCase__icon--${USE_CASES.COMMERCE}.svg");
  }

  :global(.service--${EMAIL_SERVICES.MAILCHIMP}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.MAILCHIMP}.png");
  }
  :global(.service--${EMAIL_SERVICES.INTERCOM}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.INTERCOM}.png");
  }
  :global(.service--${EMAIL_SERVICES.SENDINBLUE}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.SENDINBLUE}.png");
  }
  :global(.service--${EMAIL_SERVICES.HUBSPOT}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.HUBSPOT}.png");
  }
  :global(.service--${EMAIL_SERVICES.CONVERT_KIT}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.CONVERT_KIT}.png");
  }
  :global(.service--${EMAIL_SERVICES.SENDGRID}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.SENDGRID}.png");
  }
  :global(.service--${EMAIL_SERVICES.MAILGUN}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.MAILGUN}.png");
  }

  :global(.service--${EMAIL_SERVICES.ACTIVECAMPAIGN}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.ACTIVECAMPAIGN}.png");
  }
  :global(.service--${EMAIL_SERVICES.CAMPAIGN_MONITOR}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.CAMPAIGN_MONITOR}.png");
  }
  :global(.service--${EMAIL_SERVICES.CONSTANT_CONTACT}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.CONSTANT_CONTACT}.png");
  }
  :global(.service--${EMAIL_SERVICES.KLAVIYO}::after) {
    background-image: url("/assets/service--${EMAIL_SERVICES.KLAVIYO}.png");
  }

  :global(.service--${SOCIAL_SERVICE.INSTAGRAM}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.INSTAGRAM}.png");
  }
  :global(.service--${SOCIAL_SERVICE.LINE}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.LINE}.png");
  }
  :global(.service--${SOCIAL_SERVICE.MESSENGER}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.MESSENGER}.png");
  }
  :global(.service--${SOCIAL_SERVICE.QQ}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.QQ}.png");
  }
  :global(.service--${SOCIAL_SERVICE.SNAPCHAT}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.SNAPCHAT}.png");
  }
  :global(.service--${SOCIAL_SERVICE.TELEGRAM}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.TELEGRAM}.png");
  }
  :global(.service--${SOCIAL_SERVICE.WECHAT}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.WECHAT}.png");
  }
  :global(.service--${SOCIAL_SERVICE.WHATSAPP}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.WHATSAPP}.png");
  }
  :global(.service--${SOCIAL_SERVICE.TWITTER}::after) {
    background-image: url("/assets/service--${SOCIAL_SERVICE.TWITTER}.svg");
  }

  :global(.service--${VIDEO_SERVICES.ZOOM}::after) {
    background-image: url("/assets/service--${VIDEO_SERVICES.ZOOM}.svg");
  }
  :global(.service--${VIDEO_SERVICES.MEET}::after) {
    background-image: url("/assets/service--${VIDEO_SERVICES.MEET}.svg");
  }

  :global(.service--${SMS_SERVICES.TWILIO}::after) {
    background-image: url("/assets/service--${SMS_SERVICES.TWILIO}.png");
  }
  :global(.service--${SMS_SERVICES.MESSAGEBIRD}::after) {
    background-image: url("/assets/service--${SMS_SERVICES.MESSAGEBIRD}.png");
  }

  :global(.service--${CONTACTLESS_SERVICES.IOS}::after) {
    background-image: url("/assets/service--${CONTACTLESS_SERVICES.IOS}.svg");
  }
  :global(.service--${CONTACTLESS_SERVICES.ANDROID}::after) {
    background-image: url("/assets/service--${CONTACTLESS_SERVICES.ANDROID}.svg");
  }

  :global(.before__image::before) {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    height: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
  }

  :global(.hero_bg::before) {
    background-position: top;
    background-size: contain;
    background-image: url("/assets/hero_bg.svg");
  }

  @media (max-width: ${PHONE}px) {
    :global(.hero_bg_integrations_zapier::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
    :global(.hero_bg_integrations_zapier::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.hero_bg_integrations_zapier::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg_integrations_zapier.svg");
    }
  }

  @media (max-width: ${PHONE}px) {
    :global(.hero_bg_integrations_webflow::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
    :global(.hero_bg_integrations_webflow::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.hero_bg_integrations_webflow::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg_integrations_webflow.svg");
    }
  }

  @media (max-width: ${PHONE}px) {
    :global(.hero_channel_bg::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
    :global(.hero_channel_bg::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_bg.svg");
    }
  }
  @media (min-width: ${DESKTOP}px) {
    :global(.hero_channel_bg::before) {
      background-position: top;
      background-size: contain;
      background-image: url("/assets/hero_channel_bg.svg");
    }
  }

  :global(.the_problem_bg::before) {
    z-index: -1;
    background-position: top;
    background-size: contain;
    background-image: url("/assets/the_problem_bg.svg");
  }

  :global(.the_solution_bg::before) {
    z-index: -1;
    background-position: top;
    background-size: contain;
    background-image: url("/assets/the_solution_bg.svg");
  }

  :global(.magic_checkout_link_bg::before) {
    // z-index: -1;
    background-position: top;
    background-size: contain;
    background-image: url("/assets/magic_checkout_link_bg.svg");
  }

  .squiggly-line {
    fill: none;
    stroke: #809b8b;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke-dasharray: 100;
    animation: squiggle 1s linear infinite;
  }
  @keyframes squiggle {
    to {
      stroke-dashoffset: -200;
    }
  }

  // USE CASE
  // -- Email

  @media (max-width: 30em) {
    .${IMAGES.USE_CASE_EMAIL}--wrapper {
      height: 19rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    .${IMAGES.USE_CASE_EMAIL}--wrapper {
      height: 16rem;
    }
  }
  @media (min-width: 60em) {
    .${IMAGES.USE_CASE_EMAIL}--wrapper {
      height: 22rem;
    }
  }

  .${IMAGES.USE_CASE_EMAIL}--img {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.USE_CASE_EMAIL}.png");
  }

  @media (max-width: 30em) {
    .${IMAGES.USE_CASE_EMAIL}--img {
      background-position: center;
    }
  }

  @media (min-width: 30em) {
    .${IMAGES.USE_CASE_EMAIL}--img {
      background-position: right;
    }
  }
`;
