import IMAGES from "utils/constants/image";
import PATHS, { DOCS_PATHS, LEARN_PATHS } from "utils/paths";

export const FORM_HIGHLIGHT_SECTION = {
  header: {
    title: "Payments & data combined",
    body: [
      "Capture data from your customers and have total control over payment terms.",
      "Get the full power of Stripe Checkout combined with flexible data capture."
    ]
  },
  list: {
    header: "",
    items: [
      {
        title: "Checkout details",
        body:
          "Configure prices, discounts, automatic taxes and more with the most powerful no-code builder for Stripe Checkout.",
        link: {
          href: PATHS.BUILDER_APPLICATIONS,
          copy: "Try it",
          icon: "rightArrow"
        }
      },
      {
        title: "Add multiple fields",
        body:
          "Add as many fields as you need to capture order data. Available fields include input, select, tax id, legal, datepickers and more.",
        link: {
          href: PATHS.BUILDER_APPLICATIONS,
          copy: "Try it",
          icon: "rightArrow"
        }
      },
      {
        title: "Automation ready",
        body:
          "Connect your checkout form and sale data to any other service using our official Zapier app and REST API.",
        link: {
          href: LEARN_PATHS.AUTOMATION.OVERVIEW,
          zone: true,
          copy: "Learn more",
          icon: "rightArrow"
        }
      }
    ]
  },
  image: IMAGES.PAYMENT_DATA_CONFIG
};

export const LOGIC_AND_PREFILL_HIGHLIGHT_SECTION = {
  header: {
    title: "Logic and prefill",
    body: [
      "Set form logic to create various checkout scenarios for your customers.",
      "In just a few clicks you can define what form selections will trigger different checkout scenarios."
    ]
  },
  list: {
    header: "",
    items: [
      {
        title: "Combination fields",
        body: "Add combination fields like a select to enable form logic."
      },
      {
        title: "Simple when, then logic",
        body:
          "Define which form value combinations will trigger a specific checkout scenario.",
        link: {
          href: PATHS.BUILDER_APPLICATIONS,
          copy: "Try it",
          icon: "rightArrow"
        }
      },
      {
        title: "Upsells and more",
        body:
          "Use logic to offer conditional upsells and varied payment terms to your customers."
      }
    ]
  },
  image: IMAGES.LOGIC_PREFILL_CONFIG
};

export const AUTOMATION_HIGHLIGHT_SECTION = {
  header: {
    title: "Automation",
    body: [
      "Each checkout event can be used to power your automation workflows.",
      "Use the official PriceBlocs Zapier app to connect your data to your other apps or build your own integration using our REST API."
    ]
  },
  list: {
    header: "",
    items: [
      {
        title: "Instant triggers",
        body:
          "Our official Zapier integration has instant triggers for both checkout started and completed events so you can build any workflow you need.",
        link: {
          href: LEARN_PATHS.AUTOMATION.ZAPIER.SETUP,
          zone: true,
          copy: "Setup Zap",
          icon: "rightArrow"
        }
      },
      {
        title: "Filter and format",
        body:
          "Filter your event data using the Zap trigger filters so that you're listening for only the data you need. Additionally, pre-format your response to make it easier to work with.",
        link: {
          href: LEARN_PATHS.AUTOMATION.ZAPIER.FILTER,
          zone: true,
          copy: "Learn more",
          icon: "rightArrow"
        }
      },
      {
        title: "Comprehensive data",
        body:
          "Each checkout object contains everything you need for post-processing including Stripe values and PriceBlocs form data.",
        link: {
          href: DOCS_PATHS.PAYMENTS.CHECKOUTS.OBJECT,
          zone: true,
          copy: "Checkout docs",
          icon: "rightArrow"
        }
      },
      {
        title: "Custom integrations",
        body:
          "Use the Checkout and Payment Link's API to build your own custom integration.",
        link: {
          href: PATHS.DOCS,
          zone: true,
          copy: "See API docs",
          icon: "rightArrow"
        }
      }
    ]
  }
};
