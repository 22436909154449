import css from "styled-jsx/css";
import { PHONE } from "utils/constants/ui";

export const styles = css`
  .code--wrapper {
    font-size: 1.125rem;
    line-height: 1.625;
  }

  pre.code__pre {
    border-width: 1px;
    overflow-x: auto;
    white-space: break-spaces;
    word-break: break-all;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: whitesmoke;
  }

  pre.code__pre--preview {
    border-width: 1px;
    overflow-x: auto;
    // Retain indentation with break-spaces
    white-space: break-spaces;
    word-break: break-all;
    font-size: 0.875em;
    line-height: 1.7142857;
    padding: 0.8571429em 1.1428571em;
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: whitesmoke;
  }

  pre.code__pre--preview--small {
    border-width: 1px;
    overflow-x: auto;
    // Retain indentation with break-spaces
    white-space: break-spaces;
    word-break: break-all;
    font-size: 0.8em;
    line-height: 1.7142857;
    padding: 0.8571429em 1.1428571em;
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: whitesmoke;
  }

  @media (max-width: ${PHONE}px) {
    pre.code__pre {
      width: calc(100vw - 2rem);
    }
  }

  .code__pre .token-line {
    line-height: 1.3rem;
    height: 1.3rem;
  }

  .code__line {
    display: table-row;
  }

  .code__line--no {
    display: table-cell;
    text-align: right;
    padding-right: 1rem;
    user-select: none;
    opacity: 0.5;
  }

  .code__line--content {
    display: table-cell;
  }
`;
