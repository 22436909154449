import React, { useState } from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import { styles } from "./styles";
import CopyToClipboard from "components/CopyToClipboard";
import { ToastProvider } from "react-toast-notifications";

const DEFAULT_CLASSES = {
  pre: "code__pre ma0"
};

const Snippet = ({
  code,
  withLines,
  language = "jsx",
  customClasses,
  hideCopyAction = false
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const [showSnippet, setShowSnippet] = useState(false);

  let containerProps = {
    className: "w-100 relative"
  };

  if (!hideCopyAction) {
    containerProps.onMouseEnter = () => {
      if (!showSnippet) {
        setShowSnippet(true);
      }
    };
    containerProps.onMouseLeave = () => {
      if (showSnippet) {
        setShowSnippet(false);
      }
    };
  }

  return (
    <ToastProvider placement="bottom-left">
      <div {...containerProps}>
        <Highlight
          {...defaultProps}
          theme={theme}
          code={code}
          language={language}
        >
          {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <pre className={`${classes.pre} ma0 ${className}`} style={style}>
              {tokens.map((line, i) =>
                withLines ? (
                  <div
                    className="code__line"
                    key={i}
                    {...getLineProps({ line, key: i })}
                  >
                    <div
                      style={{ wordBreak: "normal" }}
                      className="code__line--no"
                    >
                      {i + 1}
                    </div>
                    <div className="code__line--content">
                      {line.map((token, key) => (
                        <span key={key} {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                )
              )}
              <style jsx>{styles}</style>
            </pre>
          )}
        </Highlight>
        {showSnippet && (
          <div className="absolute top-0 right-0">
            <CopyToClipboard
              tooltip="Copy snippet"
              toast="Copied snippet"
              value={code}
            />
          </div>
        )}
      </div>
    </ToastProvider>
  );
};

export default Snippet;
