import React from "react";
import Link from "next/link";
import classnames from "classnames";
import Pill from "components/Pill";

const FooterColumns = ({ columns }) => {
  return columns.map((column, columnIx) => {
    return (
      <ul
        key={columnIx}
        className="list w-100 w-25-ns ma0 pa0 dib flex flex-column"
      >
        <li
          className={classnames(
            "fl v-top flex flex-column w-100 pt0-ns mt0-ns",
            {
              "pt3 mt2": columnIx > 0
            }
          )}
        >
          <div className="f5 fw5 pb2">{column.header}</div>
          {column.nodes.map(
            ({ href, title, onClick, callout }, columnLinkIx) => {
              let content = null;
              if (href) {
                content = (
                  <Link href={href} as={href}>
                    <a
                      title={title}
                      className="link f6 dib pointer pv2 mv1 fw5 link__decoration--hover"
                    >
                      {title}
                    </a>
                  </Link>
                );
              }
              if (onClick) {
                content = (
                  <a
                    title={title}
                    onClick={onClick}
                    className="link f6 dib pointer pv2 mv1 fw5 link__decoration--hover"
                  >
                    {title}
                  </a>
                );
              }

              return (
                <div
                  key={columnLinkIx}
                  className="w-100 flex flex-row items-center"
                >
                  {content}
                  {callout && (
                    <Pill
                      copy={callout}
                      customClasses={{
                        container: "pl2",
                        copy: "f7 bg-black-05 pa1 br2 fw5 flex-shrink-0 ph2 fw6"
                      }}
                    />
                  )}
                </div>
              );
            }
          )}
        </li>
      </ul>
    );
  });
};

export default FooterColumns;
