import React, { useState } from "react";
import Snippet from "components/Snippet";
import { useViewport } from "utils/context";
import { CODE_PREVIEW_MAX_HEIGHT } from "constants/docs";
import CopyToClipboard from "components/CopyToClipboard";
import CopyToClipboardButton from "components/CopyToClipboardButton";
import { COPY_ACTION_TYPE } from "utils/constants/ui";
import { ToastProvider } from "react-toast-notifications";
import { NO_INDENT_LANGUAGES } from "constants/docs";

const DEFAULT_CLASSES = {
  container: "w-100 bg-white ba hairline-1 br3 overflow-hidden shadow-0",
  header: "flex flex-row justify-between items-center pa2 ma1",
  title: "fw6 f7 ttu w-100",
  body: "bt w-100 hairline-1 flex flex-column overflow-y-scroll",
  snippet: {
    pre: "code__pre--preview ma0 overflow-auto"
  }
};

/**
 * Should have dynamic height delta based on prior components in list
 */
const CodePreview = ({
  title,
  snippets,
  staticMaxHeight,
  maxHeight = CODE_PREVIEW_MAX_HEIGHT["0"],
  hideCopyAction = false,
  copyActionType = COPY_ACTION_TYPE.ICON,
  customClasses
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const { isDesktop } = useViewport();
  const languages = snippets ? Object.keys(snippets) : [];
  const [language, _] = useState(languages[0]);

  let code = snippets ? snippets[language] : "";
  if (NO_INDENT_LANGUAGES.indexOf(language) === -1) {
    code = JSON.stringify(code, null, 2);
  }
  const snippetContainerStyles = isDesktop
    ? staticMaxHeight
      ? {
          maxHeight: staticMaxHeight
        }
      : {
          maxHeight: `calc(100vh - ${maxHeight}px)`
        }
    : {};

  const commonCopyProps = {
    tooltip: "Copy snippet",
    toast: "Copied snippet",
    value: code
  };
  const copyComponent =
    copyActionType === COPY_ACTION_TYPE.ICON ? (
      <CopyToClipboard
        customClasses={{
          button:
            "pa0 ma0 items-center flex dib items-center justify-center bn br1 panel--action relative bg-white",
          default: "bg-white",
          active: "color-primary"
        }}
        size={13}
        {...commonCopyProps}
      />
    ) : (
      <CopyToClipboardButton copy="Copy" {...commonCopyProps} />
    );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <ToastProvider placement="bottom-left">{copyComponent}</ToastProvider>
      </div>
      <div className={classes.body} style={snippetContainerStyles}>
        <Snippet
          customClasses={classes.snippet}
          hideCopyAction={hideCopyAction}
          code={code}
          language={language}
          withLines
        />
      </div>
    </div>
  );
};

export default CodePreview;
