import React from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import Link from "next/link";
import { ENTITY_HOVER_UNDERLINE } from "utils/constants/ui";
import classnames from "classnames";

const DEFAULT_CLASSES = {
  container: "fw6 f5 color-primary flex items-center pointer",
  link: "fw6 link color-primary link__decoration--hover"
};

const IconLink = ({ href, copy, icon, customClasses, zone, ...props }) => {
  const Icon = getIconForUID(icon);
  const classes = {
    ...DEFAULT_CLASSES,
    ...customClasses
  };

  let leftPosition;
  let rightPosition;
  if (icon && icon.match && icon.match("left")) {
    leftPosition = <Icon />;
  } else {
    rightPosition = <Icon />;
  }

  const linkClass = classnames(classes.link, {
    pr1: rightPosition,
    pl1: leftPosition
  });

  return (
    <span className={classes.container}>
      {leftPosition}
      {href ? (
        <Link href={href}>
          <a {...props} className={`${ENTITY_HOVER_UNDERLINE} ${linkClass}`}>
            {copy}
          </a>
        </Link>
      ) : (
        <span {...props} className={`${ENTITY_HOVER_UNDERLINE} ${linkClass}`}>
          {copy}
        </span>
      )}
      {rightPosition}
    </span>
  );
};
export default IconLink;
