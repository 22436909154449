import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import copyToClipboard from "copy-to-clipboard";
import Analytics from "utils/analytics";
import Button from "components/Form/fields/Button";
import { UI_THEME } from "utils/constants/ui";
const DEFAULT_TOAST = "Copied Price to clipboard";

const CopyToClipboardButton = ({
  value,
  tooltip,
  toast,
  tracking,
  onClick,
  customClasses,
  copy
}) => {
  const { addToast } = useToasts();
  const [_, setClipboard] = useState("");
  const ctxToast = toast || DEFAULT_TOAST;

  return (
    <Button
      theme={UI_THEME.SLIM}
      copy={copy}
      customClasses={customClasses}
      tooltip={tooltip || "Copy Price ID"}
      onClick={() => {
        onClick && onClick();
        tracking && Analytics.trackEventWithData(tracking);

        setClipboard(value);
        copyToClipboard(value);
        addToast(ctxToast, {
          appearance: "info",
          autoDismiss: true
        });
      }}
    />
  );
};

export default CopyToClipboardButton;
