import React from "react";
import classnames from "classnames";

const DEFAULT_CLASSES = {
  copy: "hero__title fw6 tc lh-title center w-100"
};

const HighlightHeader = ({ header = [], customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const headerCount = header.length;

  return (
    <div className={classes.copy}>
      {header.map((part, partIx) => {
        const isFirst = partIx === 0;
        const isLast = headerCount - 1 === partIx;
        const spanClasses = classnames(part.classes || "", {
          ph2: !isFirst && !isLast,
          pl2: isLast && headerCount === 2
        });
        return (
          <span key={partIx} className={spanClasses}>
            {part.copy}
          </span>
        );
      })}
    </div>
  );
};

export default HighlightHeader;
