/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { createUseContext } from "./";
import cloneDeep from "lodash/cloneDeep";
import last from "lodash/last";
import groupBy from "lodash/groupBy";
import { reduceCommands } from "utils/command";
import axios from "axios";
import { API_PATHS } from "utils/paths";

const VIDEO = "video";
export const COMMAND_TYPE = {
  VIDEO
};

export class CommandClient {
  constructor({ apiKey, debug } = {}) {
    this.apiKey = apiKey || null;
    this.debug = debug || false;
    this.rootUrl = API_PATHS.COMMANDS;
  }

  async fetch(id) {
    try {
      const requestUrl = id ? `${this.rootUrl}/${id}` : this.rootUrl;
      const props = {
        method: "get",
        url: requestUrl,
        headers: this.apiKey
          ? {
              Authorization: `Bearer ${this.apiKey}`
            }
          : {}
      };

      const response = await axios(props);
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export const useCommand = ({ id }) => {
  const client = useMemo(() => new CommandClient(), []);
  const [command, setCommand] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(
    !id ? new Error("Command id is required") : null
  );

  /**
   * Fetch provided command
   */
  const fetchCommand = async () => {
    setLoading(true);
    const result = await client.fetch(id);
    if (result instanceof Error) {
      console.error(`Error fetching command ${id}`, result);
      setError(result);
    } else {
      setCommand(result);
    }

    setLoading(false);
  };

  useEffect(() => {
    id && fetchCommand();
  }, [id]);

  return {
    loading,
    error,
    command
  };
};

export const {
  Context: CommandContext,
  ContextProvider: CommandContextProvider,
  useContext: useCommandContext
} = createUseContext(
  /* eslint-disable-next-line react/display-name */
  (Provider) => ({
    children,
    id,
    apiKey,
    commands,
    actions: customActions,
    initialValues,
    debug,
    preventKeyboardTrigger
  }) => {
    const ctxDebug = debug || process.env.NODE_ENV === "development";
    const [values, setValues] = useState({
      history:
        initialValues && initialValues.history ? initialValues.history : [],
      index: 0,
      debug: ctxDebug,
      query: "",
      open: false
    });
    const activeId = last(values.history);
    const [fetchedCommands, setFetchedCommands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const client = useMemo(
      () => new CommandClient({ apiKey, debug: ctxDebug }),
      [apiKey]
    );

    const allCommands = useMemo(() => {
      let result = [];
      [commands, fetchedCommands].forEach((collection) => {
        if (Array.isArray(collection)) {
          result = result.concat(collection);
        }
      });

      return result;
    }, [commands, fetchedCommands]);
    const commandsMap = useMemo(
      () =>
        /* eslint-disable-next-line react/display-name, react/prop-types */
        allCommands.reduce((memo, command) => {
          if (command) {
            memo[command.id] = command;
          }

          return memo;
        }, {}),
      [allCommands]
    );

    /**
     * Fetch configured commands
     */
    const fetchCommands = async () => {
      setLoading(true);
      const result = await client.fetch(id);
      if (result instanceof Error) {
        setError(result);
      } else if (Array.isArray(result)) {
        setFetchedCommands(result);
      }

      setLoading(false);
    };
    useEffect(() => {
      fetchCommands();
    }, []);

    /**
     * If there is an active id
     * - then filter to all with a parent
     * - else return all without
     */
    const { filtered, suggestions } = useMemo(
      () =>
        reduceCommands({
          commands: allCommands,
          query: values.query,
          activeId
        }),
      [allCommands, values.query, activeId]
    );

    /**
     * Get upper bound index of visible items
     * - If index is above upper bound
     * - Then reset index
     */
    let maxIndex = filtered.length;
    if (suggestions.length) {
      maxIndex += suggestions.length;
    }
    maxIndex -= 1;
    useEffect(() => {
      if (values.index > maxIndex) {
        setFieldValue("index", 0);
      }
    }, [values.index, maxIndex]);

    const setFieldValue = useCallback(
      (path, value) => {
        const updatedValues = cloneDeep(values);
        updatedValues[path] = value;
        setValues(updatedValues);
      },
      [values]
    );

    /**
     * Setup keyboard shortcut listener
     */
    useEffect(() => {
      const onKeydown = (e) => {
        const isCmdKTrigger = e.key === "k" && (e.metaKey || e.ctrlKey);
        if (isCmdKTrigger) {
          e.preventDefault();
          setFieldValue("open", true);
        }
      };
      if (!preventKeyboardTrigger) {
        window.addEventListener("keydown", onKeydown);
      }

      return () => {
        !preventKeyboardTrigger &&
          window.removeEventListener("keydown", onKeydown);
      };
    }, []);

    const setOpen = useCallback(
      (value = false) => {
        setFieldValue("open", value);
      },
      [values]
    );

    const reset = useCallback(() => {
      values.debug && console.log("Reset command bar state");

      setTimeout(
        () =>
          setValues({
            history: [],
            index: 0,
            debug: process.env.NODE_ENV === "development",
            query: "",
            open: false
          }),
        0
      );
    }, [values]);

    const addHistory = useCallback(
      (value) => {
        if (value) {
          const updatedValues = cloneDeep(values);
          updatedValues.history.push(value);
          updatedValues.debug && console.log(`Add history: ${value}`);
          updatedValues.query = "";

          updatedValues.index = 0;

          setValues(updatedValues);
        } else {
          console.log(`No history to add`);
        }
      },
      [values]
    );

    const popHistory = useCallback(() => {
      if (Array.isArray(values.history) && values.history.length) {
        const updatedValues = cloneDeep(values);
        const updatedHistory = updatedValues.history;
        const poppedHistory = updatedHistory.pop();
        updatedValues.debug && console.log(`Pop history: ${poppedHistory}`);

        updatedValues.history = updatedHistory;
        updatedValues.index = 0;
        setValues(updatedValues);
      } else {
        values.debug && console.log(`No history to pop`);
      }
    }, [values]);

    const decrementIndex = useCallback(() => {
      const updatedValues = cloneDeep(values);
      if (updatedValues.index > 0) {
        updatedValues.index -= 1;
        setValues(updatedValues);
        updatedValues.debug &&
          console.log(`Decrement to index: ${updatedValues.index}`);
      }
    }, [values]);

    const incrementIndex = useCallback(() => {
      const updatedValues = cloneDeep(values);
      const newIndex = updatedValues.index + 1;

      if (newIndex <= maxIndex) {
        updatedValues.index = newIndex;
        setValues(updatedValues);
        updatedValues.debug &&
          console.log(`Increment to index: ${updatedValues.index}`);
      }
    }, [values]);

    const performIndex = useCallback(() => {
      const allVisibleCommands = [...filtered, ...suggestions];
      const command = allVisibleCommands[values.index];

      if (command) {
        if (command.perform) {
          command.perform();
          reset && setTimeout(reset, 0);
        } else {
          addHistory(command.id);
        }
      } else {
        console.error(`No command for index: ${values.index}`);
      }
    }, [values, filtered, suggestions]);

    return (
      <Provider
        value={{
          values,
          setValues,
          setFieldValue,
          loading,
          error,
          commands: allCommands,
          commandsMap,
          filtered,
          suggestions,
          sections: groupBy(filtered, "section"),
          activeId,
          activeCommand: commandsMap[activeId],
          actions: {
            addHistory,
            setOpen,
            popHistory,
            reset,
            incrementIndex,
            decrementIndex,
            performIndex,
            ...customActions
          }
        }}
      >
        {children}
      </Provider>
    );
  }
);
