import React from "react";
import classnames from "classnames";
import IconLink from "components/IconLink";

const DEFAULT_CLASSES = {
  container: "w-100 w-50-ns fl",
  title: "fw6 f4",
  body: "pt2 f5 f4-l lh-copy"
};

const HighlightList = ({ highlights, customClasses }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  return (
    <div className={classes.container}>
      {highlights.map(({ title, body, link, id }, highlightIx) => {
        const itemProps = {
          className: classnames("w-100 db", {
            pt4: highlightIx
          }),
          key: highlightIx
        };
        if (id) {
          itemProps.id = id;
        }

        return (
          <div {...itemProps}>
            {title && <div className={classes.title}>{title}</div>}
            {body && <div className={classes.body}>{body}</div>}
            {link && (
              <div className="pt2">
                <IconLink {...link} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HighlightList;
