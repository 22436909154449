import React, { Fragment, useState } from "react";
import { getIconForUID } from "components/FormerEditor/utils";
import ReactDOM from "react-dom";
import { COLORS } from "utils/styles";
import VideoEmbed from "components/VideoEmbed";
import Modal from "components/Modal";
import LoadingSpinner from "components/LoadingSpinner";
import { useCommand } from "utils/context/command";
import { getCommandModalClasses } from "utils/command";

const DEFAULT_STYLES = {
  backgroundColor: COLORS.ctaBlue
};

const DEFAULT_CLASSES = {
  container:
    "cell-grow flex flex-column pa1 justify-center black-80 bg-white db br2 ba hairline-1 pointer",
  content: "flex flex-row justify-between items-center",
  copy: "f6 fw6 pr1"
};

const DEFAULT_COPY = "Watch";

const VideoModal = ({ customStyles, customClasses, copy, id, trigger }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const styles = { ...DEFAULT_STYLES, ...customStyles };
  const ctxCopy = copy || DEFAULT_COPY;
  const PlayIcon = getIconForUID("play");
  const [showModal, setShowModal] = useState(false);
  const { loading, command, error } = useCommand({ id });
  let ctxTrigger;
  const triggerProps = {
    className: classes.container,
    style: styles
  };
  const enabled = !loading && !error && Boolean(command);
  if (enabled) {
    triggerProps.onClick = () => {
      setShowModal(true);
    };
  }

  if (trigger) {
    ctxTrigger = React.cloneElement(trigger, triggerProps);
  } else if (command || loading) {
    ctxTrigger = (
      <div {...triggerProps}>
        <div className={classes.content}>
          {loading ? (
            <LoadingSpinner customClasses={{ ring: "blue", size: "small" }} />
          ) : command ? (
            <Fragment>
              <div className={classes.copy}>{ctxCopy}</div>
              <PlayIcon size={18} color={COLORS.blue} />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {ctxTrigger}
      {showModal
        ? ReactDOM.createPortal(
            <Modal
              showModal={showModal}
              showCloseIcon={false}
              onClose={() => setShowModal(false)}
              customClasses={getCommandModalClasses({ isVideo: true })}
            >
              <VideoEmbed {...command} />
            </Modal>,
            document.body
          )
        : null}
    </Fragment>
  );
};

export default VideoModal;
