import get from "lodash/get";
export const FEATURE_NOMINATE = "nominate";
export const FEATURE_EVALUATE = "evaluate";
export const FEATURE_OAUTH = "oauth";
export const FEATURES = {
  NOMINATE: FEATURE_NOMINATE,
  EVALUATE: FEATURE_EVALUATE,
  OAUTH: FEATURE_OAUTH
};
export const CONTEXT_MANAGER = "manager";
export const CONTEXT_SUBORDINATE = "subordinate";
export const CONTEXT_ALL = "all";
export const CONTEXT_APPLICATION = "application";
export const CONTEXTS = {
  MANAGER: CONTEXT_MANAGER,
  SUBORDINATE: CONTEXT_SUBORDINATE,
  ALL: CONTEXT_ALL,
  APPLICATION: CONTEXT_APPLICATION
};

/**
 * Deprecated
 * @param {*} capabilities
 * @param {*} feature
 * @returns
 */
export const hasAppCapability = (capabilities = [], feature) => {
  const appCapability =
    capabilities &&
    capabilities.find &&
    capabilities.find(
      ({ feature: capFeature, context: capContext }) =>
        capFeature === feature && capContext === CONTEXTS.APPLICATION
    );

  return Boolean(
    appCapability && appCapability.details && appCapability.details.enabled
  );
};

export const hasUserCapability = (
  capabilities = { app: [], user: [] },
  feature,
  context
) => {
  const userCapability = capabilities.user.find(
    ({ feature: capFeature, context: capContext }) =>
      capFeature === feature && capContext === context
  );

  return Boolean(
    userCapability && userCapability.details && userCapability.details.enabled
  );
};

export const capabilityFlags = (capabilities) => {
  return {
    app: {
      evaluate: hasAppCapability(capabilities.app, FEATURES.EVALUATE),
      nominate: hasAppCapability(capabilities.app, FEATURES.NOMINATE),
      oauth: hasAppCapability(capabilities.app, FEATURES.OAUTH)
    },
    user: {
      evaluate: hasUserCapability(
        capabilities,
        FEATURES.EVALUATE,
        CONTEXTS.ALL
      ),
      nominate: {
        manager: hasUserCapability(
          capabilities,
          FEATURES.NOMINATE,
          CONTEXTS.MANAGER
        ),
        // Can nominate a report (aka is a manager)
        report: hasUserCapability(
          capabilities,
          FEATURES.NOMINATE,
          CONTEXTS.SUBORDINATE
        )
      }
    }
  };
};

export const userCanNominateManager = (flags) =>
  Boolean(get(flags, "user.nominate.manager"), false);

export const userCanNominateReport = (flags) =>
  Boolean(get(flags, "user.nominate.report"), false);

export const userCanEvaluate = (flags) =>
  Boolean(get(flags, "user.evaluate"), false);

export const getProductFeatureEnabled = (capabilities = [], featureUID) => {
  const capability = capabilities.find(({ uid }) => uid === featureUID);
  const result = capability && Boolean(capability.value);

  return result;
};
