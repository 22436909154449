import React from "react";
import MARKETING_STYLES from "constants/styles/marketing";
import { VIDEO_MODAL_WRAPPER_ONLY_CLASSES } from "utils/constants/ui";
import VideoModal from "components/VideoModal";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";
import Button from "components/Form/fields/Button";

const VideoSection = ({ header, image, commandId, icon, action }) => {
  const styles = MARKETING_STYLES[image].styles;
  const Icon = getIconForUID(icon || "play");

  return (
    <div className="w-100 before__image magic_checkout_link_bg relative">
      <div className="w-100 relative mw8 center pt5 pb3">
        <div className="w-100 flex items-center justify-center">
          <div className="flex flex-column justify-center">
            <div className="fw6 tc dib bg-white center f4 f3-m f2-l pb2 ph3 ph0-l">
              {header.title}
            </div>
            {header.body.map((copy, copyIx) => (
              <div
                key={copyIx}
                className="tc dib bg-white center f4 pb2 lh-copy ph3 ph0-l"
              >
                {copy}
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 flex items-center justify-center">
          <div key={commandId} className="ml2 flex">
            <VideoModal
              copy="Watch"
              id={commandId}
              customClasses={{ copy: "f5 fw6 pr1" }}
            />
            {action && (
              <div className="pl2">
                <Button
                  copy={action.copy}
                  classes={{
                    button:
                      "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex flex-shrink-0"
                  }}
                  href={action.href}
                  tag={action.tag}
                ></Button>
              </div>
            )}
          </div>
        </div>
        {/* {Array.isArray(actions) && actions.map(() => {
        })} */}
      </div>
      <section className="flex-ns flex-column bg__dots--hero relative pb2 pb4-ns z-1 ph3">
        <div className="w-100">
          <div className={`${image}--wrapper`}>
            <div className={`${image}--img contain contain`} />
          </div>
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 z-2">
          <div className="w-100 flex items-center justify-center bg-transparent h-100">
            <VideoModal
              customClasses={VIDEO_MODAL_WRAPPER_ONLY_CLASSES}
              customStyles={{
                backgroundColor: "transparent"
              }}
              id={commandId}
              trigger={
                <div>
                  <div
                    style={{ width: 68, height: 68 }}
                    className="cell-grow br-100 bg-white ba hairline-1 flex items-center justify-center shadow-0"
                  >
                    <Icon size={62} color={COLORS.blue} />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </section>
      {styles}
    </div>
  );
};

export default VideoSection;
