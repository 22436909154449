import compact from "lodash/compact";
import last from "lodash/last";
import upperFirst from "lodash/upperFirst";
import { matchSorter } from "match-sorter";
import classnames from "classnames";

const NAVIGATE = "navigate";
const PERFORM_TYPE = {
  NAVIGATE
};

/**
 * Reduce commands into matches and sugestions if there are queries
 * @param {Object} params
 * @returns
 */
export const reduceCommands = ({ commands, query, activeId }) => {
  const filtered = [];
  const suggestions = [];
  let matches = [];

  /**
   * Filter commands when query is present
   */
  const hasQuery = query && query.length;
  if (hasQuery) {
    matches = matchSorter(commands, query, {
      keys: ["name", "section", "keywords"]
    });
  } else {
    matches = commands;
  }

  for (let index = 0; index < matches.length; index++) {
    const match = matches[index];
    if (match) {
      const inActiveSection =
        (!activeId && !match.parent) || (activeId && match.parent === activeId);
      if (inActiveSection) {
        filtered.push(match);
      } else if (hasQuery) {
        suggestions.push(match);
      }
    }
  }

  return {
    filtered,
    suggestions
  };
};

const sanitizeKey = (key) => upperFirst(key).replace(/-/g, " ");

export const routeConfigToCommands = ({ config, parentKey = "" }) => {
  let result = [];

  for (const key in config) {
    const keyConfig = config[key];
    const isRouteConfig = keyConfig && keyConfig.href;
    const mergedParentKey = compact([parentKey, key]).join(".");
    const mergedParentKeyParts = mergedParentKey.split(".");
    const hasMultipleIdParts = mergedParentKeyParts.length > 1;
    const parentKeyParts = parentKey.split(".");
    const hasParent = parentKeyParts.length > 1;

    const commandProps = {
      id: mergedParentKey,
      parent: hasParent ? parentKey : null,
      section: sanitizeKey(last(parentKeyParts))
    };

    if (isRouteConfig) {
      result.push({
        ...commandProps,
        name: sanitizeKey(key),
        perform: {
          type: PERFORM_TYPE.NAVIGATE,
          props: {
            href: keyConfig.href,
            as: keyConfig.as
          }
        }
      });
    } else if (keyConfig) {
      if (hasMultipleIdParts) {
        result.push({
          ...commandProps,
          name: sanitizeKey(last(mergedParentKeyParts))
        });
      }

      result = result.concat(
        routeConfigToCommands({
          config: keyConfig,
          parentKey: mergedParentKey
        })
      );
    }
  }

  return result;
};

export const getCommandModalClasses = ({ isVideo } = {}) => ({
  modal: "modal__content--command br3",
  overlay: "modal__overlay--command",
  container: classnames("bg-white bn br3-ns flex flex-column center", {
    "modal__command--container w-100 w-50-m w-40-l mw6": !isVideo,
    "modal__command--containerVideo w-50": isVideo
  })
});
