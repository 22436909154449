import { useEffect, useRef, useState } from "react";
const MOUSEDOWN = "mousedown";
const TOUCHSTART = "touchstart";

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(MOUSEDOWN, listener);
    document.addEventListener(TOUCHSTART, listener);
    return () => {
      document.removeEventListener(MOUSEDOWN, listener);
      document.removeEventListener(TOUCHSTART, listener);
    };
  }, [ref, handler]);
};

export const useScroll = () => {
  const scrollRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const node = scrollRef.current;
    if (node) {
      if (node.scrollTop > 0 && !scrolled) {
        setScrolled(true);
      } else if (node.scrollTop === 0 && scrolled) {
        setScrolled(false);
      }
    }
  };

  useEffect(() => {
    const node = scrollRef.current;
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }
    return () => {
      const node = scrollRef.current;
      if (node) {
        node.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return {
    ref: scrollRef,
    scrolled,
    setScrolled
  };
};
