import css from "styled-jsx/css";
import IMAGES from "utils/constants/image";

const featuresEditorChrome = css.resolve`
  @media (max-width: 30em) {
    :global(.${IMAGES.FEATURES_EDITOR_CHROME}--wrapper) {
      height: 14rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.FEATURES_EDITOR_CHROME}--wrapper) {
      height: 24rem;
    }
  }
  @media (min-width: 60em) {
    :global(.${IMAGES.FEATURES_EDITOR_CHROME}--wrapper) {
      height: 41rem;
    }
  }

  :global(.${IMAGES.FEATURES_EDITOR_CHROME}--img) {
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.FEATURES_EDITOR_CHROME}.png");
  }

  @media (min-width: 60em) {
    :global(.${IMAGES.FEATURES_EDITOR_CHROME}--img) {
      background-image: url("/assets/${IMAGES.FEATURES_EDITOR_CHROME}.png");
      background-position: center !important;
    }
  }
`;

const lowCodePaymentLink = css.resolve`
  @media (max-width: 30em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--wrapper) {
      height: 22rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--wrapper) {
      height: 32rem;
    }
  }
  @media (min-width: 60em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--wrapper) {
      height: 35rem;
    }
  }

  :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--img) {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.LOW_CODE_PAYMENT_LINK}.png");
  }

  @media (max-width: 30em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--img) {
      background-position: center;
    }
  }

  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--img) {
      background-position: center;
    }
  }

  @media (min-width: 60em) {
    :global(.${IMAGES.LOW_CODE_PAYMENT_LINK}--img) {
      background-position: right;
    }
  }
`;

const buildingBlockPricing = css.resolve`
  @media (max-width: 30em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--wrapper) {
      height: 22rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--wrapper) {
      height: 23rem;
    }
  }
  @media (min-width: 60em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--wrapper) {
      height: 26rem;
    }
  }

  :global(.${IMAGES.BUILDING_BLOCK_PRICING}--img) {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.BUILDING_BLOCK_PRICING}.png");
  }

  @media (max-width: 30em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--img) {
      background-position: center;
    }
  }

  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--img) {
      background-position: center;
    }
  }

  @media (min-width: 60em) {
    :global(.${IMAGES.BUILDING_BLOCK_PRICING}--img) {
      background-position: right;
    }
  }
`;

const paymentDataConfig = css.resolve`
  @media (max-width: 30em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--wrapper) {
      height: 20rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--wrapper) {
      height: 32rem;
    }
  }
  @media (min-width: 60em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--wrapper) {
      height: 32rem;
    }
  }

  :global(.${IMAGES.PAYMENT_DATA_CONFIG}--img) {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.PAYMENT_DATA_CONFIG}.png");
  }

  @media (max-width: 30em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--img) {
      background-position: center;
    }
  }

  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--img) {
      background-position: center;
    }
  }

  @media (min-width: 60em) {
    :global(.${IMAGES.PAYMENT_DATA_CONFIG}--img) {
      background-position: right;
    }
  }
`;

const logicPrefillConfig = css.resolve`
  @media (max-width: 30em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--wrapper) {
      height: 22rem;
    }
  }
  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--wrapper) {
      height: 32rem;
    }
  }
  @media (min-width: 60em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--wrapper) {
      height: 32rem;
    }
  }

  :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--img) {
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-image: url("/assets/${IMAGES.LOGIC_PREFILL_CONFIG}.png");
  }

  @media (max-width: 30em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--img) {
      background-position: center;
    }
  }

  @media (min-width: 30em) and (max-width: 60em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--img) {
      background-position: center;
    }
  }

  @media (min-width: 60em) {
    :global(.${IMAGES.LOGIC_PREFILL_CONFIG}--img) {
      background-position: right;
    }
  }
`;

export default {
  [IMAGES.FEATURES_EDITOR_CHROME]: featuresEditorChrome,
  [IMAGES.LOW_CODE_PAYMENT_LINK]: lowCodePaymentLink,
  [IMAGES.BUILDING_BLOCK_PRICING]: buildingBlockPricing,
  [IMAGES.PAYMENT_DATA_CONFIG]: paymentDataConfig,
  [IMAGES.LOGIC_PREFILL_CONFIG]: logicPrefillConfig
};
