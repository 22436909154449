import React from "react";
import Image from "components/Image";
import { PRIORITY_FIT_COVER } from "utils/constants/ui";

const HEADER = "Accept payments online with just a link";
const SUBHEADER = "No coding required. Free to create!";
const IMAGE_DIMENSIONS = {
  width: 200,
  height: 48
};

const HeroSection = ({ header, title, subheader, children }) => {
  let headerNode;
  if (Array.isArray(header)) {
    headerNode = header.map((head, headIx) => {
      return (
        <div key={headIx} className="f3 f2-l fw6 tc lh-copy w-90 center">
          {head}
        </div>
      );
    });
  } else if (typeof header === "string") {
    headerNode = (
      <div className="f3 f2-l fw6 tc lh-copy w-90 center">
        {header || HEADER}
      </div>
    );
  } else {
    headerNode = header;
  }

  return (
    <section className="flex-ns flex-column relative pb2 pb4-ns z-1">
      <div
        style={IMAGE_DIMENSIONS}
        className="mt3 mt5-l bg-transparent br2 center"
      >
        <Image
          priority
          src={"/assets/hero__logo.png"}
          alt={"priceblocs"}
          {...IMAGE_DIMENSIONS}
          {...PRIORITY_FIT_COVER}
        />
      </div>
      <div className="tc ph3 pt3 z-0">
        {headerNode}
        <div className="f4 fw5 f3-l tc lh-copy w-100 w-60-ns center black-60">
          {subheader || SUBHEADER}
        </div>
      </div>
      {children}
    </section>
  );
};
export default HeroSection;
