import css from "styled-jsx/css";

export const styles = css`
  .bg__dots--cta {
    background-color: #f6f8ff;
  }

  .bg__dots--cta::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    height: 100%;
    background-color: transparent;
    background-image: url("/assets/bg__isomesh--cta.png");
    /* transform: scale(1.3) rotate(33deg); */
    background-position: left !important;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
`;
