import React from "react";
import PropTypes from "prop-types";
import PATHS from "utils/paths";
import Button from "components/Form/fields/Button";
import { styles } from "./styles";

const LeadCTASection = ({ header, subheader, action: { href, copy } }) => (
  <section className="flex-ns items-center db mt3 relative">
    <div className="bg__dots--cta w-100 pa3 pa4-ns br2">
      <div className="mw8 center tl tl-ns">
        <div className="f3 f2-l fw6 mb2 mt0-ns tl lh-title w-70 w-40-ns fw5">
          {header}
        </div>
        <div className="f4 f3-l fw4 mb3 mt0-ns tl lh-copy pt2 w-100 w-70-ns">
          {subheader}
        </div>
        <div className="dib">
          <Button tag="a" href={href} copy={copy} />
        </div>
      </div>
    </div>
    <style jsx>{styles}</style>
  </section>
);

LeadCTASection.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  action: PropTypes.shape({
    href: PropTypes.string,
    copy: PropTypes.string
  })
};

LeadCTASection.defaultProps = {
  subheader: "Click below to get started.",
  action: { href: PATHS.SIGNUP, copy: "Create plan" }
};

export default LeadCTASection;
