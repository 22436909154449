import React from "react";
import { magicCheckoutStyles } from "components/Landing/MagicCheckout/magicCheckoutStyles";

const ContentCTA = ({ header, subheader, action }) => {
  return (
    <div className="w-100 before__image magic_checkout_link_bg relative">
      <div className="w-100 relative mw8 center pv5-l">
        <div className="w-100 flex items-center justify-center">
          <div className="flex flex-column justify-center">
            <div className="fw6 tc dib bg-white center f3 f2-l ph3 ph0-l">
              {header.title}
            </div>
            {header.body.map((copy, copyIx) => (
              <div
                key={copyIx}
                className="tc dib bg-white center f5 pb2 lh-copy ph3 ph0-l pt2"
              >
                {copy}
              </div>
            ))}
            {subheader}
          </div>
        </div>
        {action}
      </div>
      <div className="dib h3 h4-l"></div>
      <style jsx>{magicCheckoutStyles}</style>
    </div>
  );
};

export default ContentCTA;
