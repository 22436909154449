import css from "styled-jsx/css";

export default css`
  :global(.modal__content) {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
  }

  :global(.modal__content--command) {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    align-items: start !important;
    padding: 14vh 16px 16px;
  }

  :global(.modal__overlay) {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  :global(.modal__overlay--command) {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(256, 256, 256, 0.5);
  }
  :global(.modal__command--container) {
    max-height: 400px;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%);
  }
  :global(.modal__command--containerVideo) {
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%);
  }

  :global(.ReactModal__Content) {
    opacity: 0;
    display: flex;
  }

  :global(.ReactModal__Content--after-open) {
    opacity: 1;
    display: flex;
    align-items: center;
  }

  :global(.ReactModal__ContentCommand--after-open) {
    opacity: 1;
    display: flex;
    align-items: start;
  }

  :global(.ReactModal__Content--before-close) {
    opacity: 0;
  }
`;
