import Button from "components/Form/fields/Button";
import HeroSection from "components/HeroSection";
import React from "react";
import PATHS from "utils/paths";
import { PHONE, DESKTOP } from "utils/constants/ui";
import HighlightHeader from "components/HighlightHeader";

const DEFAULT_ACTION = {
  tag: "link",
  href: PATHS.SIGNUP,
  copy: "Sign up",
  classes: {
    button:
      "fw6 br2 ph3 pv2 border-box input-reset ba bg-transparent pointer f5 dib link items-center flex flex-shrink-0"
  }
};

const DEFAULT_CLASSES = {
  wrapper: "w-100 before__image hero_bg--container relative",
  container: "mw8 center relative",
  actions: "dib w4 center pt3 flex flex-column items-center justify-center"
};

const HeroBanner = ({
  header,
  subheader,
  customClasses,
  bgImage = "hero_bg",
  hideAction,
  altActions,
  action = DEFAULT_ACTION,
  children,
  paddingBottom = 80
}) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };

  return (
    <div className={`${classes.wrapper} ${bgImage}`}>
      <div className={classes.container}>
        <HeroSection
          header={<HighlightHeader header={header} />}
          subheader={subheader}
        >
          {altActions ? (
            altActions
          ) : !hideAction ? (
            <div className={classes.actions}>
              {children}
              <Button {...action} />
            </div>
          ) : null}
        </HeroSection>
      </div>
      <style global jsx>
        {`
          @media (max-width: ${PHONE}px) {
            .hero__title {
              font-size: 38px;
            }
          }
          @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
            .hero__title {
              font-size: 38px;
            }
          }
          @media (min-width: ${DESKTOP}px) {
            .hero__title {
              font-size: 58px;
              max-width: 780px;
            }
          }

          @media (max-width: ${PHONE}px) {
            .hero_bg--container {
              padding-bottom: 40px;
            }
          }
          @media (min-width: ${PHONE + 1}px) and (max-width: ${DESKTOP}px) {
            .hero_bg--container {
              padding-bottom: 40px;
            }
          }
          @media (min-width: ${DESKTOP}px) {
            .hero_bg--container {
              padding-bottom: ${paddingBottom}px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HeroBanner;
