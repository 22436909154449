import React, { Fragment } from "react";
import HighlightList from "components/HighlightList";
import MARKETING_STYLES from "constants/styles/marketing";

const DEFAULT_CLASSES = {
  left: "w-100 w-50-l fl flex flex-column",
  right: "w-100 w-50-l fr pt3 pt0-l"
};

const HighlightSection = ({ header, list, image, customClasses, right }) => {
  const classes = { ...DEFAULT_CLASSES, ...customClasses };
  const styles = MARKETING_STYLES[image]
    ? MARKETING_STYLES[image].styles
    : null;
  return (
    <Fragment>
      <div className="flex flex-row w-100 pt4 pt5-l ph3 ph0-l">
        <div className="w-100 mw8 center relative flex flex-column">
          {header.title && (
            <div className="f3 f2-l fw6 pb3">{header.title}</div>
          )}
          <div className="w-100 lh-copy pb3">
            {header.body.map((copy, copyIx) => (
              <div key={copyIx} className="f4 pb2">
                {copy}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row w-100 ph3 ph0-l">
        <div className="w-100 mw8 center relative flex flex-column flex-row-l items-start">
          <div className={classes.left}>
            <div className="f3 pt2 lh-copy">
              <div className="f4 fw6 pb3 lh-title">{list.header}</div>
              <HighlightList
                highlights={list.items}
                customClasses={{
                  container: "w-100",
                  title: "fw6 f4",
                  body: "pt2 f5 lh-copy"
                }}
              />
            </div>
          </div>
          <div className={classes.right}>
            {right || (
              <div className={`${image}--wrapper`}>
                <div className={`${image}--img contain contain`} />
              </div>
            )}
          </div>
        </div>
      </div>
      {styles}
    </Fragment>
  );
};

export default HighlightSection;
