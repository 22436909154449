import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import copyToClipboard from "copy-to-clipboard";
import HoverIconButton from "components/HoverIconButton";
import Analytics from "utils/analytics";
const DEFAULT_TOAST = "Copied Price to clipboard";

const CopyToClipboard = ({
  value,
  tooltip,
  type = "copy",
  toast,
  tracking,
  onClick,
  customClasses,
  children,
  size
}) => {
  const { addToast } = useToasts();
  const [_, setClipboard] = useState("");
  const ctxToast = toast || DEFAULT_TOAST;

  return (
    <HoverIconButton
      size={size}
      type={type}
      customClasses={customClasses}
      tooltip={tooltip || "Copy Price ID"}
      onClick={() => {
        onClick && onClick();
        tracking && Analytics.trackEventWithData(tracking);

        setClipboard(value);
        copyToClipboard(value);
        addToast(ctxToast, {
          appearance: "info",
          autoDismiss: true
        });
      }}
    >
      {children}
    </HoverIconButton>
  );
};

export default CopyToClipboard;
